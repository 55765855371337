<template>
    <div class="modal" v-if="modelValue" :class="{active : modelValue}">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>{{ title }}<span>Sort {{ title}}</span></h1>
                </div>
                <button class="close_btn left_out" @click="closeModal"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="loader_area2" v-if="loader"><quote-loader/></div>
                <div class="setting_wpr" v-else>
                    <template v-if="assetType == 'playbooks'">
                        <div class="pinned_items">
                            <h3 class="sub_heading2 mt-0">Your Teased Playbooks</h3>
                            <ul class="sorting_card pt-1">
                                <draggable v-model="assetList" :animation="200" item-key="id">
                                    <template #item="{element}">
                                        <li class="move" v-if="element.tease_enabled == 1">
                                            <img v-if="element.dashboard_thumb" :src="element.dashboard_thumb">
                                            <img v-else src="@/assets/images/thumb/default-playbook.svg">
                                            <div class="card_details">
                                                {{element.title}}
                                                <div class="action_items">
                                                    <label :for="`pinned-${element.id}`" class="switch_option capsule_btn border-0" @click="handlePin($event, element)">
                                                        <h5 class="mr-1">Pin</h5>
                                                        <input type="checkbox" :true-value="1" :false-value="0" :checked="element.is_pinned == 1" :id="`pinned-${element.id}`" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                    <label :for="`untease-${element.id}`" class="switch_option capsule_btn border-0" @click="handleTease($event, element)">
                                                        <h5 class="mr-1">Tease</h5>
                                                        <input type="checkbox" :true-value="1" :false-value="0" :checked="element.tease_enabled == 1" :id="`untease-${element.id}`" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                </div>
                                            </div>
                                        </li>
                                    </template>
                                </draggable>
                                <div class="empty_area" v-if="!teaseList.length">It looks like you haven't teased any playbooks yet.</div>
                            </ul>
                        </div>
                        <div class="pinned_items">
                            <h3 class="sub_heading2 mt-0">Your Pinned Playbooks <span class="tooltip_icon" v-tooltip="`Add a &#8220;Pinned Playbook&#8221; to the pinned training area of your client portal to spotlight key training.`" position="bottom"><i class="fas fa-info"></i></span></h3>
                            <ul class="sorting_card pt-1">
                                <draggable v-model="assetList" :animation="200" item-key="id">
                                    <template #item="{element}">
                                        <li class="move" v-if="element.is_pinned == 1 && element.tease_enabled !== 1">
                                            <img v-if="element.dashboard_thumb" :src="element.dashboard_thumb">
                                            <img v-else src="@/assets/images/thumb/default-playbook.svg">
                                            <div class="card_details">
                                                {{element.title}}
                                                <div class="action_items">
                                                    <label :for="`pinned-${element.id}`" class="switch_option capsule_btn" @click="handlePin($event, element)">
                                                        <h5 class="mr-1">Pin</h5>
                                                        <input type="checkbox" :true-value="1" :false-value="0" :checked="element.is_pinned == 1" :id="`pinned-${element.id}`" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                    <label :for="`untease-${element.id}`" class="switch_option capsule_btn border-0" @click="handleTease($event, element)">
                                                        <h5 class="mr-1">Tease</h5>
                                                        <input type="checkbox" :true-value="1" :false-value="0" :checked="element.tease_enabled == 1" :id="`untease-${element.id}`" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                </div>
                                            </div>
                                        </li>
                                    </template>
                                </draggable>
                                <div class="empty_area" v-if="!pinnedList.length">It looks like you haven't pinned any playbooks yet.</div>
                            </ul>
                        </div>
                        <h3 class="sub_heading2 mt-0">Your Unpinned Playbooks</h3>
                        <ul class="sorting_card pt-1">
                            <draggable v-model="assetList" :animation="200" item-key="id">
                                <template #item="{element}">
                                    <li class="move" v-if="element.is_pinned !== 1 && element.tease_enabled !== 1">
                                        <img v-if="element.dashboard_thumb" :src="element.dashboard_thumb">
                                        <img v-else src="@/assets/images/thumb/default-playbook.svg">
                                        <div class="card_details">
                                            {{element.title}}
                                            <div class="action_items">
                                                <label :for="`pinned-${element.id}`" class="switch_option capsule_btn" @click="handlePin($event, element)">
                                                    <h5 class="mr-1">Pin</h5>
                                                    <input type="checkbox" :true-value="1" :false-value="0" :checked="element.is_pinned == 1" :id="`pinned-${element.id}`" hidden>
                                                    <div><span></span></div>
                                                </label>
                                                <label :for="`untease-${element.id}`" class="switch_option capsule_btn border-0" @click="handleTease($event, element)">
                                                    <h5 class="mr-1">Tease</h5>
                                                    <input type="checkbox" :true-value="1" :false-value="0" :checked="element.tease_enabled == 1" :id="`untease-${element.id}`" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </div>
                                        </div>
                                    </li>
                                </template>
                            </draggable>
                        </ul>
                    </template>
                    <ul class="sorting_card" v-else>
                        <draggable v-model="assetList" :animation="200" item-key="id">
                            <template #item="{element}">
                                <li class="move">
                                    <img v-if="element.dashboard_thumb" :src="element.dashboard_thumb">
                                    <img v-else src="@/assets/images/thumb/default-playbook.svg">
                                    <div class="card_details">
                                        {{element.title}}
                                    </div>
                                </li>
                            </template>
                        </draggable>
                    </ul>
                </div>
            </div>
            <div class="modal_footer">
                <button :disabled="sortLoader" type="button" class="btn cancel_btn" @click="closeModal">Cancel</button>
                <button :disabled="sortDefaultLoader" type="button" class="btn save_btn" @click="handleSortDefaultAsset" v-show="assetType != 'habit-tracking'"><i class="fa fa-spinner fa-spin" v-if="sortDefaultLoader"></i> {{ sortDefaultLoader ? 'Reseting' : 'Reset' }}</button>
                <button :disabled="sortLoader" type="button" class="btn save_btn" @click="handleSortAsset"><i class="fa fa-spinner fa-spin" v-if="sortLoader"></i> {{ sortLoader ? 'Saving' : 'Save' }}</button>
            </div>
        </div>
        <tease-component v-model="teaseConfirmation" :service="service" :asset="activeAsset" />
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions } from 'vuex'

    const TeaseComponent = defineAsyncComponent(() => import('@/components/TeaseComponent'))

    import Draggable from 'vuedraggable';
    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'

    export default {
        name: 'Sort Asset',

        data () {
            return {
                assetList: [],
                pinnedList:[],
                teaseList:[],
                activeAsset: {},
                teaseConfirmation: false,
            }
        },

        props: {
            modelValue: Boolean,
            title: String,
            assetType: String,
            updateSorting: Function,
            folderId: {
                type: [Number, String],
                default: null,
            },
            folderScope: {
                type: Number,
                default: 1,
            },
            service: String,
        },

        emit: ['update:modelValue'],

        components: {
            Draggable,
            TeaseComponent,
        },

        watch: {
            modelValue (value) {
                const vm = this;

                if (value) {
                    vm.assetList  = [];

                    setTimeout(() => {
                        vm.getSortedAssets({ asset_type: vm.assetType, folder_id: vm.folderId, folder_scope: vm.folderScope })
                    }, 1000);

                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            assets(assets) {
                const vm = this;

                vm.assetList = assets;
                vm.pinnedPlaybooks();
                vm.teaseAssets();
            },

            teaseConfirmation (val) {
                const vm = this;

                if (!val) {
                    vm.getSortedAssets({ asset_type: vm.assetType, folder_id: vm.folderId, folder_scope: vm.folderScope })
                }
            }
        },

        computed: mapState({
            assets: state => state.commonModule.sortedAssets,
            loader: state => state.commonModule.loader,
            sortLoader: state => state.commonModule.sortLoader,
            sortDefaultLoader: state => state.commonModule.sortDefaultLoader,
        }),

        methods: {
            ...mapActions({
                getSortedAssets: 'commonModule/getSortedAssets',
                sortAsset: 'commonModule/sortAsset',
                sortDefaultAsset: 'commonModule/sortDefaultAsset',
                pinUnpinPlaybook: 'playbookModule/pinUnpinPlaybook',
                enableDisableTease: 'teaseModule/enableDisableTease',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            handleSortAsset () {
                const vm = this;

                let id   = vm.assetList.map((asset) => asset.id).reverse();

                const form = { id: id, asset_type: vm.assetType };

                vm.sortAsset(form).then((result) => {
                    if (result) {
                        const field  = (vm.assetType == 'habit-tracking' || vm.assetType == 'progress-tracking' || vm.assetType == 'newsletters') ? 'order' : 'ordering';
                        const filter = { title: 'Custom Arrangement', field, order: 'desc'};

                        vm.updateSorting(filter);
                        vm.getSortedAssets({ asset_type: vm.assetType, folder_id: vm.folderId, folder_scope: vm.folderScope })
                        vm.closeModal();
                    }
                });
            },

            handleSortDefaultAsset () {
                const vm = this;

                vm.sortDefaultAsset({ asset_type: vm.assetType }).then((result) => {
                    if (result) {
                        const filter = { title: 'Date Created', field: 'id', order: 'desc'};

                        vm.updateSorting(filter);
                        vm.getSortedAssets({ asset_type: vm.assetType, folder_id: vm.folderId, folder_scope: vm.folderScope })
                        vm.closeModal();
                    }
                });
            },

            handlePin (event, playbook) {
                const vm = this;

                const options = Helper.swalConfirmOptions('Are you sure?', `You want to ${playbook.is_pinned ? 'unpin' : 'pin'} this playbook ${playbook.is_pinned ? 'from' : 'in'} your client portal!`);
                      options.preConfirm = function () {
                                                return vm.pinUnpinPlaybook({ id: playbook.id, is_pinned: playbook.is_pinned ? 0 : 1 }).then((result) => {
                                                    if (result) {
                                                        const filter = { title: 'Date Created', field: 'id', order: 'desc'};

                                                        vm.getSortedAssets({ asset_type: vm.assetType, folder_id: vm.folderId, folder_scope: vm.folderScope });
                                                        vm.updateSorting(filter);
                                                    }
                                                });
                                            };

                Swal.fire(options).then((result) => {
                    if (!result.isConfirmed) {
                        event.preventDefault();
                        event.target.checked = playbook.is_pinned ? true : false;
                    }
                });

                vm.pinnedPlaybooks();
            },

            pinnedPlaybooks () {
                const vm = this;
                vm.pinnedList = [];

                if (Object.values(vm.assetList).length) {
                    vm.assetList.forEach(function(el) {
                        if (el.is_pinned == 1) {
                            vm.pinnedList.push(el);
                        }
                    });
                }
            },

            handleTease (event, asset) {
                const vm = this;

                if (asset.tease_enabled == 1) {
                    const options = Helper.swalConfirmOptions('Are you sure?', `You want to ${asset.tease_enabled ? 'untease' : 'tease'} this asset ${asset.is_pinned ? 'from' : 'in'} your client portal!`);
                          options.preConfirm = function () {
                                                    return vm.enableDisableTease({ id: asset.tease_setting.id, enabled: asset.tease_enabled ? 0 : 1 }).then((result) => {
                                                        if (result) {
                                                            const filter = { title: 'Date Created', field: 'id', order: 'desc'};

                                                            vm.getSortedAssets({ asset_type: vm.assetType, folder_id: vm.folderId, folder_scope: vm.folderScope });
                                                            vm.updateSorting(filter);
                                                        }
                                                    });
                                                };

                    Swal.fire(options).then((result) => {
                        if (!result.isConfirmed) {
                            event.preventDefault();
                            event.target.checked = asset.tease_enabled ? true : false;
                        }
                    });
                } else {
                    vm.activeAsset = asset;
                    vm.teaseConfirmation = true;
                }

                vm.teaseAssets();
            },

            teaseAssets () {
                const vm = this;
                vm.teaseList = [];

                if (Object.values(vm.assetList).length) {
                    vm.assetList.forEach(function(el) {
                        if (el.tease_enabled == 1) {
                            vm.teaseList.push(el);
                        }
                    });
                }
            },
        }
    }
</script>

<style>
    .pinned_items{
        background: #f5f5f5;
        border-bottom: 1px solid #e9e9e9;
        margin: 0 -45px 30px -45px;
        padding: 0 45px 15px 45px;
    }
    .sorting_card li {
        border: 0;
        background: #fff;
        border-radius: 8px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 15px;
        box-shadow: none;
        transition: all 0.3s ease-in-out;
        overflow: hidden;
    }

    .sorting_card li img {
        width: 100px;
        height: auto;
        object-fit: cover;
    }

    .sorting_card .card_details {
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        color: #121525;
        padding: 10px 15px;
        flex-grow: 1;
    }

    .sorting_card .card_details p {
        font-size: 10px;
        line-height: 13px;
        font-weight: 400;
        color: #5a5a5a;
        margin-top: 3px;
    }
    .sorting_card .card_details .action_items{
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .sorting_card .card_details .action_items .capsule_btn{
        margin: 0 10px 0 0;
    }

    .sorting_card li:hover {
        background: #fff;
        box-shadow: 0px 0px 30px rgba(0,0,0,0.06);
    }
    .sorting_card li label.capsule_btn{
        justify-content: flex-start;
        padding: 7px 0 0 0;
    }
    .sorting_card li label.capsule_btn h5{
        font-size: 12px;
        color: #5a5a5a;
    }
    .sorting_card li label.capsule_btn div{
        transform: scale(0.7);
    }

    .empty_area {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        line-height: 20px;
        font-weight: 400;
        color: #5a5a5a;
        padding: 20px 15px;
        background: #fff;
        border-radius: 8px;
        margin-bottom: 15px;
    }
</style>
